<template>
  <BasicCrud
    ref="crud"
    :title="$t('Students')"
    path="student"
    :show-new-button="false"
    custom-search-event="search"
    @search="searchFor"
    :filters="filters"
    @filter="filterChanged"
    fluid
  >
    <template v-slot:aftersearch>
      <v-btn @click="download" style="margin-left:10px" icon>
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>

    <template v-slot:table>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="loading"
        :items-per-page="20"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000]}"
        :server-items-length="itemsLength"
      >
        <template v-slot:item.fullname="{ item }">
          <span class="text-truncate">{{ item.fullname }}</span>
        </template>

        <template v-slot:item.r.title="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.event.product.title : null }}</span>
        </template>

        <template v-slot:item.u.name="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.event.unit.name : null }}</span>
        </template>

        <template v-slot:item.e.start_at="{ item }">
          <span class="text-truncate">{{ item.voucher ? parseDateValue(item.voucher.payment.event.start_at) + ' - ' + parseDateValue(item.voucher.payment.event.finish_at) : null }}</span>
        </template>

        <template v-slot:item.c.group="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.customer.group : null }}</span>
        </template>

        <template v-slot:item.p.id="{ item }">
          {{ item.voucher ? item.voucher.payment.id.toString().padStart(9, '0') : null }}
        </template>

        <template v-slot:item.c.fullname="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.customer.fullname : null }}</span>
        </template>

        <template v-slot:item.p.updated_at="{ item }">
          <span class="text-truncate">{{ parseDateValue(item.voucher.payment.updated_at) + ' ' + item.voucher.payment.updated_at.substring(10) }}</span>
        </template>

        <template v-slot:item.v.status="{ item }">
          <v-chip small dark v-if="item.voucher && item.voucher.status" :color="getStatusColor(item.voucher.status)">
            {{ $t('voucher_status_' + item.voucher.status) }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            @click="markDelivered(item)"
            v-if="item.voucher && item.voucher.status == 'unvailable'"
          >mdi-account-arrow-up</v-icon>
        </template>
      </v-data-table>
    </template>
  </BasicCrud>
</template>

<script>

import Vue from 'vue'
import BasicCrud from '@/components/BasicCrud'
import { mapState } from 'vuex'
import api from '@/services/api'
import i18n from '@/plugins/i18n.js'
import { DateTime } from "luxon"

export default {
  name: "Product",

  components: {
    BasicCrud
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  computed: mapState({
    headers: state => state.student.headers
  }),

  mounted() {
    this.loadItems()
  },

  data() {
    return {
      items: [],
      options: {},
      itemsLength: 20,
      loading: false,
      search: null,
      filters: [
        {
          type: 'select',
          items: [],
          value: null,
          name: 'u.id',
          event: 'filter',
          label: i18n.t('Unit'),
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          items: [],
          value: null,
          name: 'r.id',
          event: 'filter',
          label: i18n.t('Product'),
          itemText: 'title',
          itemValue: 'id'
        },
        {
          type: 'select',
          items: [],
          value: null,
          name: 'e.id',
          event: 'filter',
          label: i18n.t('Event'),
          itemText: 'title',
          itemValue: 'id'
        },
        {
          type: 'select',
          items: [
            {
              id: 'pending',
              name: i18n.t('voucher_status_pending'),
            },
            {
              id: 'unvailable',
              name: i18n.t('voucher_status_unvailable'),
            },
            {
              id: 'delivered',
              name: i18n.t('voucher_status_delivered'),
            },
            {
              id: 'canceled',
              name: i18n.t('voucher_status_canceled'),
            }
          ],
          value: null,
          name: 'v.status',
          event: 'filter',
          label: i18n.t('Status'),
          itemText: 'name',
          itemValue: 'id'
        }
      ]
    }
  },

  methods: {
    reloadData() {
      this.loading = true

      let options = {...this.$route.query}

      if (this.options.sortBy && this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc && this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.page = this.options.page
      options.search = this.search

      this.filters.forEach((filter) => {
        if (filter.value) {
          options['filters[' + filter.name + ']'] = filter.value
        }
      })

      options["filters[e.active]"] = 1

      api
        .find('student', options)
        .then((response) => {
          this.items = response.data.results
          this.itemsLength = response.data.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    loadItems() {
      api
        .find('unit', {order: 'name', limit: 500})
        .then((response) => Vue.set(this.filters[0], 'items', response.data.results))

      api
        .find('product', {order: 'title', limit: 500})
        .then((response) => Vue.set(this.filters[1], 'items', response.data.results))

      api
        .find('event', {order: 'start_at', limit: 500,  "filters[active]": 1})
        .then((response) => {
          let events = []

          response.data.results.forEach(evt => events.push(
            {id: evt.id, title: this.parseDateValue(evt.start_at) + ' - ' + this.parseDateValue(evt.finish_at)}
          ))

          Vue.set(this.filters[2], 'items', events)
        })
    },

    searchFor(search) {
      this.search = search
      this.reloadData()
    },

    markDelivered(item) {
      api.update('voucher/' + item.voucher.id, {}, 'update_status_to_delivered')
        .then(() => this.reloadData())
    },

    parseDateValue(dateValue) {
      return DateTime.fromISO(dateValue.replace(' ', 'T')).setLocale(i18n.locale).toLocaleString()
    },

    getStatusColor(status) {
      if (status == 'delivered') return 'green'
      if (status == 'canceled') return 'red'
      if (status == 'pending') return 'blue'

      return 'gray'
    },

    filterChanged() {
      this.reloadData()
    },

    download() {
      let headers = [
        this.$t('ID'),
        this.$t('Product'),
        this.$t('Unit'),
        this.$t('Event') + ' - ' + this.$t('Start at'),
        this.$t('Event') + ' - ' + this.$t('Finish at'),
        this.$t('Student'),
        this.$t('Student') + ' ' + this.$t('email'),
        this.$t('School or group'),
        this.$t('Payment ID'),
        this.$t('Amount'),
        this.$t('Customer'),
        this.$t('Customer') + ' ' + this.$t('email'),
        this.$t('Voucher delivered')
      ]

      let items = []

      this.items.forEach((item) => {
        let itemValues = [
          item.id,
          item.voucher ? item.voucher.payment.event.product.title : '',
          item.voucher ? item.voucher.payment.event.unit.name : '',
          item.voucher ? this.parseDateValue(item.voucher.payment.event.start_at) : '',
          item.voucher ? this.parseDateValue(item.voucher.payment.event.finish_at) : '',
          item.fullname,
          item.email ? item.email : '',
          item.voucher && item.voucher.payment.customer.group ? item.voucher.payment.customer.group : '',
          item.voucher ? item.voucher.payment.id : '',
          item.voucher && item.voucher.payment.amount ? item.voucher.payment.amount.toFixed(2) : '',
          item.voucher ? item.voucher.payment.customer.fullname : '',
          item.voucher ? item.voucher.payment.customer.email : '',
          item.voucher ? this.$t('voucher_status_' + item.voucher.status) : ''
        ]

        items.push(itemValues)
      })

      this.exportCSVFile(headers, items, 'report')
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
          items.unshift(headers)
      }

      let jsonObject = JSON.stringify(items)
      
      let csv = this.convertToCSV(jsonObject)
      
      let exportedFilename = fileTitle + '.csv'
      
      let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename)
        return
      }

      var link = document.createElement("a");

      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob)

        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilename)
        link.style.visibility = 'hidden'

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
      }
    },

    convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
        var str = ''

        for (var i = 0; i < array.length; i++) {
            var line = ''
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index]
            }

            str += line + '\r\n'
        }

        return str;
    }
  }
}

</script>
